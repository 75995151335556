<template>
  <div class="EcommerceProviderTucompra">
    <button
      class="ui-button --main"
      type="button"
      :disabled="hasErrors"
      @click="openPopup"
    >Pagar {{ $cr(payment.value, payment.currency) }}</button>
    <div>
      <small>Vía TuCompra</small>
    </div>
    <!-- Errores de la pasarela -->
		<div class="is_error" v-for="(error, i) in errors" :key="i">
			<div :class="'type-' + error.type">
				<ui-icon size="22" :value="error.icon" color="inherit"></ui-icon>
				<span class="title"> {{ error.type }} </span>
			</div>
			<span class="info-msg">{{ error.msg }}</span>
		</div>
  </div>
</template>

<script>
import { useI18n } from '@/modules/i18n';
import * as popup from '@/modules/ecommerce/helpers/popupWindow.js';

export default {
  name: 'EcommerceProviderTucompra',
  mixins: [useI18n],

  props: {
    payment: {
      type: Object,
      required: false,
    },

    // BACKWARDS COMPAT
    transaction: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      isDone: false,
      errors: [],
    };
  },

  methods: {
    openPopup() {
      this.isDone = false;
      if (!this.hasErrors) {
        let form = this.createForm();
        this.$emit('send', this.transaction);

        popup.submit(
          form,
          'height=768,width=1024,resizable=yes,toolbar=no,status=yes',
          this.onPopupMessage,
          this.onPopupClose
        );
      }
    },

    onPopupMessage(message) {
      this.isDone = true;

      if (message && typeof message.ok != 'undefined') {
        this.result = message.ok;

        if (this.result) {
          this.$emit('success', message);
        } else {
          this.$emit('error', message);
        }
      }
    },

    onPopupClose() {
      if (!this.isDone) {
        this.$emit('error', {warning: 'transaction without finish'});
      }
    },

    createForm() {
      // BACKWARDS COMPAT
      let payment = this.payment ? this.payment : this.transaction;

      // Create the form
      var form = document.createElement('form');
      form.setAttribute('method', 'post');
      form.setAttribute('action', payment.clientData.url);

      Object.keys(payment.clientData).forEach((fieldName) => {
        if (fieldName == 'url') {
          return;
        }

        var hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', fieldName);
        hiddenField.setAttribute('value', payment.clientData[fieldName]);
        form.appendChild(hiddenField);
      });

      document.body.appendChild(form);
      return form;
    },

    async validateData() {
      let flag = false;

      // Chequeo que la transacción sea realizada por un usuario valido del sistema, no por "system"
      if (this.transaction.payer == 'system') {
        this.errors.push({ type: "warning", icon: "g:report", msg: "Not valid user" });
        flag = true;
      }
      if (flag) {
        return;
      }
    },
  },

  mounted() {
		this.validateData();
	},

  computed: {
		hasErrors() {
			return this.errors.length > 0;
		},
	},
};
</script>

<style lang="scss" scoped>
  .is_error {
      line-height: 1rem;
      align-items: center;
      min-width: 200px;
      width: 300px;
      left: 0;
      margin: -1px 0;
      z-index: 10;
      padding: 10px 5px 10px 10px;
      background: rgba(255,255,255,.9);
      border-left: 5px solid #78909C;
      margin-top: 8px;
      box-shadow: rgba(149, 157, 165, 0.20) 0px 8px 24px;
      .type-error {
          display: flex;
          align-items: center;
          width: 100%;
          padding-bottom: 10px;
          color: #F44336;
      }
      .type-warning {
          display: flex;
          align-items: center;
          width: 100%;
          padding-bottom: 10px;
          color: rgb(8, 70, 163);
      }
      .title {
          padding-left: 5px;
          font-weight: 400;
          font-size: 16px;
      }
  }
  .info-msg {
      position: relative;
      display: inline-block;
      width: 100%;
  }
  </style>